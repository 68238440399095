<template lang="">
  <base-layout>
    <VehicleManufactureAddEdit
      @add-edit="$store.dispatch('fsTable/fetchData')"
    />
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <TitlePlus
            title="Vehicle Manufacturer"
            @plus="add"
            :hide-plus="false"
          />
        </div>
        <div class="summary-card-container">
          <SummaryCard
            title="Total "
            :value="indexMetaData.count.total"
            variant="gray"
            size="large"
          />
        </div>
      </div>

      <FSTable
        :fst-id="`manufactureIndex`"
        :searchEnabled="false"
        :headers="tableHeaders"
        :endpoint="indexDataEndpoint"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #default="{ data }">
          <FSTableRow
            v-for="(item, itemIndex) in data"
            :key="itemIndex"
            text-fallback-always
          >
            <FSTableRowItem
              :text="item.id"
              :truncate="-5"
              :to="{
                name: 'VehicleManufacturersDetails',
                params: { id: item.id },
              }"
            />

            <FSTableRowItem :text="item.name" />
            <FSTableRowItem :text="getCountryName(item)" />
            <FSTableRowItem :text="getVehicleType(item)" />
            <FSTableRowItem
              ><div class="flex items-center">
                <oto-edit-icon @click="edit(item)" /></div
            ></FSTableRowItem>
          </FSTableRow>
        </template>
      </FSTable>
    </content-section>
  </base-layout>
</template>
<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'
import VehicleManufactureAddEdit from '@/views/vehicle-manufacturers/VehicleManufactureAddEdit.vue'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import { VehicleManufactureConfig } from '@/config/VehicleManufactureConfig'
import { EventBus } from '@/utils'
import { useCountryIndex } from '@/composables'
import SummaryCard from '@/components/cards/SummaryCard'
export default {
  name: 'VehicleManufacturersIndex',
  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    OtoEditIcon,
    VehicleManufactureAddEdit,
    SummaryCard,
  },
  data() {
    return {
      indexMetaData: {
        count: {
          total: 0,
        },
      },
      indexDataEndpoint: VehicleManufactureConfig.api.index(),

      tableHeaders: [
        { text: 'ID', width: '10%', sort: 'id' },
        { text: 'Manufacturer Name', width: '30%', sort: 'null' },
        { text: 'Headquarter Location', width: '30%', sort: 'null' },
        { text: 'Vehicle Class', width: '20%', sort: 'null' },
        { text: 'Actions', width: '10%', sort: 'null' },
      ],
      countries: [],
    }
  },
  async created() {
    this.countries = await useCountryIndex()
      .then(({ data }) => data)
      .catch((e) => console.log(e))
  },
  methods: {
    getCountryName(item) {
      return item?.country?.name ?? '--'
    },
    getVehicleType(item) {
      // return this.vehicleTypeMap[item.vehicle_class].text
      return item.vehicle_classes ? item.vehicle_classes.join(', ') : '--'
    },
    add() {
      EventBus.$emit(VehicleManufactureConfig.events.editingData, {
        countries: this.countries,
        item: {},
      })
      dispatchEvent(new Event(VehicleManufactureConfig.events.sorToggle))
    },
    edit(item) {
      console.log(item)
      EventBus.$emit(VehicleManufactureConfig.events.editingData, {
        item: item,
        countries: this.countries,
      })
      dispatchEvent(new Event(VehicleManufactureConfig.events.sorToggle))
    },
  },
}
</script>
<style lang=""></style>
